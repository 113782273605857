import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {UpgradeModule} from '@angular/upgrade/static';
import {AjsConfiguration} from './ajs-configuration';
import {
  angularGridInstanceProvider,
  authServiceProvider,
  coreAuthServiceProvider,
  coreResultProvider,
  eventServiceProvider,
  pRouterProvider,
  sessionServiceProvider,
  ssoServiceProvider
} from './ajs-upgraded-providers';
import {environment} from '../environments/environment';
import {ReactiveFormsModule} from '@angular/forms';
import {registerLocaleData} from '@angular/common';
import localeIt from '@angular/common/locales/it';
// import {NgPassproproCoreAjsModule} from "@rgi/ng-passpropro-core/portal";
// import {NgPassproproSurveyAjsModule} from "@rgi/ng-passpropro-survey";
// import {NgPassproproProductAjsModule} from "@rgi/ng-passpropro/product";
// import {NgPassProProConsultancyPortalModule} from "@rgi/ng-passpropro/consultancy-portal";
// import {NgPassProProQuestionnaireAjsModule} from "@rgi/ng-passpropro/questionnaire-portal";
// import {PassProProRuleEditorPortalModule} from "@rgi/ng-passpropro/rule-editor-portal";
import {PortalConfig, RGI_RX_PORTAL_CONFIG, RgiLangInterceptor, RgiRxPortalModule} from "@rgi/rx/portal";
import {RGI_RX_AUTH_HTTP_INTERCEPTORS} from "@rgi/rx/auth";
import {RGI_RX_HTTP_CONFIG, RGI_RX_HTTP_INTERCEPTORS, RgiRxHttpConfig, RgiRxHttpModule} from "@rgi/rx/http";
import {RgiRxI18nModule} from "@rgi/rx/i18n";
import {DynamicScriptLoaderService} from './dynamic-script-loader.service';
import {IsaRbmLibPortalModule} from '@rgi/isa-rbm-lib/portal';
import {RgiHeadersInterceptorService} from './rgi-headers-interceptor.service';
import {NetworkInterceptorService} from "./services/interceptors/network-interceptor.service";
import {SsoInterceptorService} from "./sso-interceptor.service";
import {RefreshInterceptorService} from "./services/interceptors/refresh-interceptor.service";
import {MY_AUTH_CONFIG, MyAuthConfig} from "./services/refresh_token/refresh-token-api";
import {RefreshTokenService} from "./services/refresh_token/refresh-token.service";

registerLocaleData(localeIt);

const myAuthConfig: MyAuthConfig = {
  refreshTokenHeader: "Bearer",
  preMatchingRefreshTokenProvider: RefreshTokenService
}


const portalConfig: PortalConfig = {
  module: "app",
  ui: {
    enableGlobalHttpLoadingIndicator: true
  },
  i18n: {
    mergeCatalog: false
  }
}

const rgiRxHttpConfig: RgiRxHttpConfig = {
  gzip: false
}

@NgModule({
  imports: [
    BrowserModule,
    UpgradeModule,
    HttpClientModule,
    ReactiveFormsModule,
    RgiRxPortalModule.forRoot([]),
    RgiRxHttpModule,
    RgiRxI18nModule,
    IsaRbmLibPortalModule
//     NgPassproproCoreAjsModule,
//     NgPassproproSurveyAjsModule,
//     NgPassproproProductAjsModule,
//     PassProProRuleEditorPortalModule,
//     NgPassProProQuestionnaireAjsModule,
//     NgPassProProConsultancyPortalModule
  ],
  providers: [
    coreAuthServiceProvider,
    RGI_RX_AUTH_HTTP_INTERCEPTORS,
    RGI_RX_HTTP_INTERCEPTORS,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RgiLangInterceptor,
      multi:true
    },
    authServiceProvider,
    angularGridInstanceProvider,
    sessionServiceProvider,
    pRouterProvider,
    coreResultProvider,
    eventServiceProvider,
    ssoServiceProvider,
    DynamicScriptLoaderService,
    {provide: 'environment', useValue: environment},
    {provide: LOCALE_ID, useValue: 'it'},
    {provide: MY_AUTH_CONFIG, useValue: myAuthConfig},
    {provide: RGI_RX_PORTAL_CONFIG, useValue: portalConfig},
    {provide: RGI_RX_HTTP_CONFIG, useValue: rgiRxHttpConfig},
    {provide: HTTP_INTERCEPTORS, useClass: RefreshInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: SsoInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: NetworkInterceptorService, multi: true,},
    {provide: HTTP_INTERCEPTORS, useClass: RgiHeadersInterceptorService, multi: true}
  ],
  bootstrap: [],
})
export class AppModule {


  constructor(private upgrade: UpgradeModule, private dynamicLoader: DynamicScriptLoaderService) {}

  ngDoBootstrap() {
    AjsConfiguration();
    this.dynamicLoader.load('environment').then(data => {
       // Script Loaded Successfully
       this.upgrade.bootstrap(document.body, ['app'], { strictDi: true });
       console.log('after bootstrap');
    }).catch(error => console.log(error));
  }
}


