import { Injectable } from '@angular/core';
import {HttpRequest} from "@angular/common/http";
import * as CryptoJS from 'crypto-js';
import {optional} from "optional-chain";
import {Configuration, EnvConfigurationService} from "./config/env-configuration.service";

const DIRTY_CHARS = [
  { dirty: 'Ã ', clean: 'à' },
  { dirty: 'Ã', clean: 'à' },
  { dirty: 'â', clean: "'" },
  { dirty: 'à¨', clean: 'è' },
  { dirty: 'à¬', clean: 'ì' },
  { dirty: 'à©', clean: 'é' },
  { dirty: 'à²', clean: 'ò' },
  { dirty: 'à¹', clean: 'ù' },
  { dirty: 'Â°', clean: '°' },
  { dirty: 'à', clean: 'À' },
  { dirty: 'à', clean: 'È' },
  { dirty: 'à', clean: 'Ì' },
  { dirty: 'à', clean: 'Ò' },
  { dirty: 'à', clean: 'Ù' },
  { dirty: 'â°', clean: '‰' },
  { dirty: "a'", clean: 'à', skipHTML: true }, // mapping per fix lettera con carattere ricorsivo (PP)
  { dirty: 'â¬', clean: '€' },
  { dirty: 'à', clean: 'È' },
  { dirty: 'Â', clean: '' },
];

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  private configuration: Configuration;

  private userLogin: unknown = {};
  private token: string = '';
  private authenticated: boolean = false;
  private expirationTokenFromHash: string = '';
  private sessionId: string = '';
  private sessionData: string = '';

  constructor(private readonly envConfigurationService: EnvConfigurationService) {
    this.configuration = this.envConfigurationService.getConfiguration();
  }

  // Getters
  isAuthenticated(): boolean {
    return this.authenticated;
  }

  isPostCookie(url = ''): boolean {
    return url.includes(this.configuration.cookie_path);
  }

  isAesEnabled(url = ''): boolean {
    return (this.configuration.auth_enabled && (this.configuration.debug_mode === 'disabled' || this.isPostCookie(url)));
  }

  isSignatureEnabled(request: HttpRequest<unknown>): boolean {
    const url = optional(request).k('url').get() || '';
    return this.isAesEnabled(url);
  }

  getUserLogin(): unknown {
    return this.userLogin;
  }

  getToken(): string {
    return this.token;
  }

  getExpirationTokenFromHash(): string {
    return this.expirationTokenFromHash;
  }

  getSessionId(): string {
    return this.sessionId;
  }

  getSessionData(): string {
    return this.sessionData;
  }

  getIsaAuthorizeUrl(): string {
    const url_api = this.getUrlGatewayHost();
    return `${url_api}${this.configuration.authorize_path}?client_id=${this.configuration.client_id}&redirect_uri=${this.configuration.redirect_uri_to_client}&scope=openid%20AIS&response_type=id_token%20token&display=page&nonce=skbnfv372&acr_values=idp:internal&prompt=login&state=${this.configuration.redirect_uri_to_client}`;
  }

  getUrlGatewayHost(): string {
    if (this.configuration.api_https_enabled === 'enabled') {
      return `https://${this.configuration.gateway_host}:${this.configuration.gateway_port_https}`;
    }
    return `http://${this.configuration.gateway_host}:${this.configuration.gateway_port}`;
  }

  // Setters
  setUserLogin(value: unknown) {
    this.userLogin = value;
  }

  setToken(value: string) {
    this.token = value;
  }

  setAuthenticated(value: boolean) {
    this.authenticated = value;
  }

  setExpirationTokenFromHash(value: string) {
    this.expirationTokenFromHash = value;
  }

  setSessionId(value: string) {
    this.sessionId = value;
  }

  setSessionData(value: string) {
    this.sessionData = value;
  }

  // Security
  encrypt(message: string) {
    if (this.configuration.newApiGw && this.configuration.newApiGw === 'enabled') {
      return message;
    }
    const key = CryptoJS.enc.Hex.parse(this.asciiToHex(this.configuration.aes_key));
    const iv = CryptoJS.enc.Hex.parse(this.asciiToHex(this.configuration.aes_iv));
    return CryptoJS.AES.encrypt(this.configuration.aes_iv + message, key, { iv }).toString();
  }

  decrypt(encrypted: string) {
    if (this.configuration.newApiGw && this.configuration.newApiGw === 'enabled') {
      return encrypted;
    }
    const key = CryptoJS.enc.Hex.parse(this.asciiToHex(this.configuration.aes_key));
    const IV = CryptoJS.enc.Hex.parse(this.asciiToHex(this.configuration.aes_iv));
    const decryptedHex = CryptoJS.AES.decrypt(encrypted, key, { iv: IV }).toString();
    const decrypted_with_IV = this.hexToAscii(decryptedHex);
    return decrypted_with_IV.substring(16) || encrypted;
  }

  asciiToHex(str: string) {
    const arr1 = [];
    for (let n = 0, l = str.length; n < l; n++) {
      const hex = Number(str.charCodeAt(n)).toString(16);
      arr1.push(hex);
    }
    return arr1.join('');
  }

  hexToAscii(str: string) {
    const hex = str.toString();
    let res = '';
    for (let n = 0; n < hex.length; n += 2) {
      res += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    return res;
  }

  createDigest(text = '') {
    const sha = CryptoJS.enc.Hex.parse(this.sha256(text));
    return `SHA-256=${CryptoJS.enc.Base64.stringify(sha)}`;
  }

  createSignature(digest: string, method: string, path: string, host: string) {
    if (this.configuration.newApiGw && this.configuration.newApiGw === 'enabled') {
      return '';
    }
    const signing_str = `(request-target): ${method} ${path}\nhost: ${host}\ndigest: ${digest}`;
    const sig = CryptoJS.enc.Base64.stringify(
        CryptoJS.HmacSHA256(signing_str, this.configuration.signature_key)
    );
    return `keyID="${this.configuration.id_key}",algorithm="hmac-sha256",headers="(request-target) host digest",signature="${sig}"`;
  }

  sha256(ascii: string) {
    function rightRotate(value: number, amount: number) {
      return (value >>> amount) | (value << (32 - amount));
    }

    const mathPow = Math.pow;
    const maxWord = mathPow(2, 32);
    const lengthProperty = 'length';
    let i = 0;
    let j = 0;
    let result = '';

    const words = [];
    const asciiBitLength = ascii[lengthProperty] * 8;

    let hash = [];
    const k = [];
    let primeCounter = 0;

    const isComposite = {};
    for (let candidate = 2; primeCounter < 64; candidate++) {
      if (!isComposite[candidate]) {
        for (i = 0; i < 313; i += candidate) {
          isComposite[i] = candidate;
        }
        hash[primeCounter] = (mathPow(candidate, 0.5) * maxWord) | 0;
        k[primeCounter++] = (mathPow(candidate, 1 / 3) * maxWord) | 0;
      }
    }

    ascii += '\x80'; // Append Ƈ' bit (plus zero padding)
    while ((ascii[lengthProperty] % 64) - 56) {
      ascii += '\x00';
    } // More zero padding
    for (i = 0; i < ascii[lengthProperty]; i++) {
      j = ascii.charCodeAt(i);
      if (j >> 8) {
        return;
      } // ASCII check: only accept characters in range 0-255
      words[i >> 2] |= j << (((3 - i) % 4) * 8);
    }
    words[words[lengthProperty]] = (asciiBitLength / maxWord) | 0;
    words[words[lengthProperty]] = asciiBitLength;

    // process each chunk
    for (j = 0; j < words[lengthProperty]; ) {
      const w = words.slice(j, (j += 16)); // The message is expanded into 64 words as part of the iteration
      const oldHash = hash;
      // This is now the undefinedworking hash", often labelled as variables a...g
      // (we have to truncate as well, otherwise extra entries at the end accumulate
      hash = hash.slice(0, 8);

      for (i = 0; i < 64; i++) {
        // Expand the message into 64 words
        // Used below if
        const w15 = w[i - 15],
            w2 = w[i - 2];

        // Iterate
        const a = hash[0],
            e = hash[4];
        const temp1 =
            hash[7] +
            (rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25)) + // S1
            ((e & hash[5]) ^ (~e & hash[6])) + // ch
            k[i] +
            // Expand the message schedule if needed
            (w[i] =
                i < 16
                    ? w[i]
                    : (w[i - 16] +
                        (rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15 >>> 3)) + // s0
                        w[i - 7] +
                        (rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2 >>> 10))) | // s1
                    0);
        // This is only used once, so *could* be moved below, but it only saves 4 bytes and makes things unreadble
        const temp2 =
            (rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22)) + // S0
            ((a & hash[1]) ^ (a & hash[2]) ^ (hash[1] & hash[2])); // maj

        hash = [(temp1 + temp2) | 0].concat(hash) // We don't bother trimming off the extra ones, they're harmless as long as we're truncating when we do the slice()
        hash[4] = (hash[4] + temp1) | 0;
      }

      for (i = 0; i < 8; i++) {
        hash[i] = (hash[i] + oldHash[i]) | 0;
      }
    }

    for (i = 0; i < 8; i++) {
      for (j = 3; j + 1; j--) {
        const b = (hash[i] >> (j * 8)) & 255;
        result += (b < 16 ? 0 : '') + b.toString(16);
      }
    }
    return result;
  }

  normalizeCarriageReturn(data?: string) {
    if (data !== undefined) {
      return data.replace(/[\r\n]/g, '');
    }
    return 'undefined';
  }

  decryptResponseFromGateway(data) {
    let output = data;
    let isHTML = false;

    try {
      if (typeof data === 'string') {
        isHTML = data.trim().startsWith('<');
        if (!isHTML) {
          return JSON.parse(data);
        }
      } else {
        return data;
      }
    } catch (ex) {
      const encryptedText = data.replace(/(?:\r\n|\r|\n)/g, '');
      output = this.decrypt(encryptedText);
    }

    DIRTY_CHARS.forEach(function (obj) {
      if (!isHTML || (isHTML && !obj.skipHTML)) {
        output = output.split(obj.dirty).join(obj.clean);
      }
    });

    try {
      const result = output ? JSON.parse(output) : null;
      if (this.configuration.name !== 'LOCALE') {
        console.log('DECRYPTED RESPONSE:', result);
      }
      return result;
    } catch (ex) {
      if (this.configuration.name !== 'LOCALE') {
        console.log('CATCH DECRYPTED OUTPUT:', output);
      }
      return null;
    }
  }
}
