import {Inject, Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {Configuration, EnvConfigurationService} from "./services/config/env-configuration.service";

@Injectable({
  providedIn: 'root'
})
export class SsoInterceptorService implements HttpInterceptor {

    coreAuthService: any;
    authService: any
    private configuration: Configuration;

    constructor(@Inject('coreAuthService') coreAuthService: any, @Inject('authService') authService: any,
                private readonly envConfigurationService: EnvConfigurationService) {
        this.coreAuthService = coreAuthService;
        this.authService = authService;
        this.configuration = this.envConfigurationService.getConfiguration();
    }

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        return next.handle(req).pipe( tap(() => {},
        (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status !== 401) {
                    return;
                }
                console.log("error", next);
                console.log("configuration:", this.configuration);
                this.coreAuthService.setNotAuthenticated();
                this.coreAuthService.setToken(null);
                window.location.href = this.configuration.isaSSOLogoutPath;
            }
        }));
    }
}
