import {Inject, Injectable, Injector, Optional} from '@angular/core';
import {HttpEvent, HttpEventType, HttpRequest, HttpResponse} from "@angular/common/http";
import {TokenManagerProviderService} from "./token-manager-provider.service";
import {combineLatest, from, Observable, of} from "rxjs";
import {map} from "rxjs/operators";
import {MY_AUTH_CONFIG, MyAuthConfig, MyAuthToken, MyAuthTokenRefreshProvider} from "./refresh-token-api";

@Injectable({
  providedIn: 'root'
})
export class HandleRefreshTokenService {

  constructor(private tokenManagerProviderService: TokenManagerProviderService,
              @Optional() @Inject(MY_AUTH_CONFIG) private myAuthConfig: MyAuthConfig, private injector: Injector) { }

  handleRefreshTokenResponseHeaders = (event: HttpEvent<any>) => {
    if (event.type === HttpEventType.Response) {
      if (this.myAuthConfig && event instanceof HttpResponse && !!this.myAuthConfig.refreshTokenHeader && event.headers.has(this.myAuthConfig.refreshTokenHeader)) {
        this.tokenManagerProviderService.token = event.headers.get(this.myAuthConfig.refreshTokenHeader);
      }
    }
  }

  handleRefreshTokenFn = (event: HttpEvent<any>): Observable<HttpEvent<any>> => {
    if (!!this.myAuthConfig && event instanceof HttpResponse && !!this.myAuthConfig.refreshTokenProvider) {
      const myAuthTokenRefreshProvider = this.injector.get<MyAuthTokenRefreshProvider>(this.myAuthConfig.refreshTokenProvider);
      const refreshTokenFnResult = myAuthTokenRefreshProvider.refresh(this.tokenManagerProviderService.token);
      if (!refreshTokenFnResult) {
        return of(event);
      }
      if (typeof refreshTokenFnResult === 'string') {
        this.tokenManagerProviderService.token = refreshTokenFnResult;
      } else {
        return combineLatest(from(refreshTokenFnResult), of(event))
            .pipe(
                map(([tokenResult, evt]) => {
                  this.tokenManagerProviderService.token = tokenResult;
                  return evt;
                })
            );
      }
    }
    return of(event);
  }

  handlePreMatchingRefreshTokenFn$ = (): Observable<MyAuthToken | undefined> => {
    if (!this.myAuthConfig || !this.myAuthConfig.preMatchingRefreshTokenProvider) {
      return of(undefined);
    }
    const myAuthTokenPreMatchingRefreshProvider = this.injector.get<MyAuthTokenRefreshProvider>(this.myAuthConfig.preMatchingRefreshTokenProvider);
    const refreshTokenFnResult = myAuthTokenPreMatchingRefreshProvider.refresh(this.tokenManagerProviderService.token);
    if (!refreshTokenFnResult) {
      return of(undefined);
    } else if (typeof refreshTokenFnResult === 'string') {
      this.tokenManagerProviderService.token = refreshTokenFnResult;
      return of(refreshTokenFnResult);
    }
    return from(refreshTokenFnResult)
        .pipe(
            map(tokenResult => {
              this.tokenManagerProviderService.token = tokenResult;
              return tokenResult;
            })
        );
  }

  setAuthorization(req: HttpRequest<any>, token?: string): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token ? token : this.tokenManagerProviderService.token}`
      }
    });
  }


}
