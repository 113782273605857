import {InjectionToken, Type} from "@angular/core";

export type MyAuthToken = string;

export interface MyAuthTokenRefreshProvider {
    refresh(token: string): MyAuthToken | Promise<MyAuthToken> | void;
}

export interface MyAuthConfig {
    refreshTokenHeader?: string;

    refreshTokenProvider?: Type<MyAuthTokenRefreshProvider>;

    preMatchingRefreshTokenProvider?: Type<MyAuthTokenRefreshProvider>;
}

export const MY_AUTH_CONFIG = new InjectionToken<MyAuthConfig>('MY_AUTH_CONFIG');

