import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';

import {environment} from './environments/environment';
import {LogLevel, rgiRxBrowserPlatformLogger} from "@rgi/rx";
import {APP_CONFIG} from "./app/services/config/config";
import {Configuration} from "./app/services/config/env-configuration.service";

fetch('/environments/configuration/ext')
    .then((response) => {
        return response.json();
    })
    .then((configuration: Configuration) => {
        if (environment.production) {
            enableProdMode();
        }
        platformBrowserDynamic([
            { provide: APP_CONFIG, useValue: configuration },
            rgiRxBrowserPlatformLogger({
                level: environment.production ? LogLevel.ERROR : LogLevel.TRACE
            }),
        ])
            .bootstrapModule(AppModule)
            .catch(err => console.log(err));
    });
