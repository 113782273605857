// ANGULARJS CONFIGURATION
declare var angular: angular.IAngularStatic;

export function AjsConfiguration() {
  angular.module('core').config([
    'eventServiceProvider',
    function (evProvider) {
      evProvider.registerEvent('gettextLanguageChanged', {type: 'i18n'});
    }
  ]);
}


