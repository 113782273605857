import {Inject, Injectable, InjectionToken} from '@angular/core';
import {APP_CONFIG} from "./config";

export type Configuration = {
  production?: boolean
  name?: string
  version?: string
  localhost?: string
  contextpath?: string
  parent_origin?: string
  parent_origin_fol?: string

  auth_enabled?: boolean
  isa_domain?: string
  api_https_enabled?: string
  gateway_host?: string
  gateway_port?: string
  gateway_port_https?: string
  client_id?: string
  redirect_uri_to_client?: string
  authorize_path?: string
  cookie_path?: string
  newApiGw?: 'enabled' | 'disabled'
  consent_path?: string
  id_key?: string
  aes_key?: string
  aes_iv?: string
  signature_key?: string
  debug_mode?: 'enabled' | 'disabled'
  isa_login?: 'enabled' | 'disabled'

  mock_baseurl?: string
  mock_checkpoint?: boolean
  mock_all_enabled?: boolean
  mock_all_disabled?: boolean

  isaPPOProtocol?: string,
  isaPPOHost?: string,
  isaPPOPort?: string,
  isaSSOSamlLogin?: string,
  isaSSOSamlAuthorizePath?: string,
  isaSSOLogoutPath?: string,
  isaSSOTokenKey?: string
}

@Injectable({
  providedIn: 'root'
})
export class EnvConfigurationService {

  private configuration: Configuration

  constructor(@Inject(APP_CONFIG) config: Configuration) {
    this.configuration = config
  }

  public load(configuration?: Configuration) {
    if (!this.configuration && configuration) {
      this.configuration = configuration;
    }
  }

  public getConfiguration() {
    return this.configuration;
  }

}
