import {Inject, Injectable} from '@angular/core';
import {fromEvent, Observable, of} from "rxjs";
import {map, take} from "rxjs/operators";
import {MyAuthToken} from "./refresh-token-api";

@Injectable({
  providedIn: 'root'
})
export class MyKeyCloackService {

  coreAuthService: any;
  ssoService: any;
  eventObservable: Observable<any>;

  constructor(@Inject('coreAuthService') coreAuthService: any, @Inject('SSOService') ssoService: any) {
    this.coreAuthService = coreAuthService;
    this.ssoService = ssoService;
    this.eventObservable = fromEvent(window, 'message');
  }

  setToken(token: string) {
    this.coreAuthService.setToken(token);
  }

  getToken(valid: boolean) : Promise<MyAuthToken> {
    /*of(this.coreAuthService.getToken()).pipe(
        mergeMap((token) => {
          return combineLatest(
              of(token),
              this.eventObservable.pipe(take(1))
          );
        }),
        map(([token, event]) =>{
          console.log('token', token);
          console.log('event', event);
        })
    );*/

    if (valid) {
      return of(this.coreAuthService.getToken()).toPromise();
    } else {
      //return this.eventObservable.pipe(take(1)).toPromise();
      return this.eventObservable.pipe(
          take(1),
          map((event: MessageEvent) => {
            this.ssoService.setDateTokenReceived();
            return event.data.token;
          })
      ).toPromise();
    }
  }
}
