import {Inject, Injectable} from '@angular/core';
import {MyAuthToken, MyAuthTokenRefreshProvider} from "./refresh-token-api";
import {MyKeyCloackService} from "./my-key-cloack.service";

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenService implements MyAuthTokenRefreshProvider {

  private callRefresh = false;
  private readonly enableRefreshToken: boolean = false;

  ssoService: any

  constructor(@Inject('SSOService') ssoService: any, private myKeyCloackService: MyKeyCloackService) {
    this.ssoService = ssoService;
    this.enableRefreshToken = this.ssoService.isaSSORefreshTokenEnable;
  }

  refresh(token: string): MyAuthToken | Promise<MyAuthToken> | void {
    return this.tokenBehaviorManager(token);
  }

  tokenBehaviorManager(token: string): MyAuthToken | Promise<MyAuthToken> | void {
    if (this.enableRefreshToken) {
      return this.performTokenWithRefresh(token);
    } else {
      return this.performToken();
    }
  }

  private performTokenWithRefresh(token: string): MyAuthToken | Promise<MyAuthToken> | void {
    const validToken = this.ssoService.getToken() && this.ssoService.isValidToken();
    if (!validToken && !this.callRefresh) {
        console.log("token is going to be refreshed...");
        this.refreshToken();
        this.callRefresh = true;
    }
    return this.myKeyCloackService.getToken(validToken).then(
        tokenMyKeyCloack => {
          this.callRefresh = false;
          return tokenMyKeyCloack;
        }
    );
  }

  private performToken(): MyAuthToken | Promise<MyAuthToken> | void {
    return this.myKeyCloackService.getToken(true).then(
        tokenMyKeyCloack => {
          return tokenMyKeyCloack;
        }
    )
  }

  private refreshToken() {
    const element: HTMLIFrameElement = document.querySelector("#refreshFrame");
    let obj = element.contentDocument;
    this.ssoService.refreshToken(obj);
  }

}
