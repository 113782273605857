import {Inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {mergeMap, tap} from "rxjs/operators";
import {HandleRefreshTokenService} from "../refresh_token/handle-refresh-token.service";

@Injectable({
  providedIn: 'root'
})
export class RefreshInterceptorService implements HttpInterceptor {

    ssoService: any;
    constructor(@Inject('SSOService') ssoService: any, private handleRefreshTokenService: HandleRefreshTokenService) {
        this.ssoService = ssoService;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.handleRefreshTokenService.handlePreMatchingRefreshTokenFn$().pipe(
            mergeMap(token => {
                return next.handle(this.handleRefreshTokenService.setAuthorization(req, token))
                    .pipe(
                        mergeMap(next => this.handleRefreshTokenService.handleRefreshTokenFn(next)),
                        tap(next => {
                            this.handleRefreshTokenService.handleRefreshTokenResponseHeaders(next)
                        })
                    );
                }
            )
        );
  }
}
