import { Injectable } from '@angular/core';
import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse
} from "@angular/common/http";
import {Observable} from "rxjs";
import {Configuration, EnvConfigurationService} from "../config/env-configuration.service";
import {AuthorizationService} from "../authorization.service";
import {LoadingService} from "../loading.service";
import {catchError, delay, finalize, map} from "rxjs/operators";

const LOCALE: string = 'LOCALE';

@Injectable({
  providedIn: 'root'
})
export class NetworkInterceptorService implements HttpInterceptor {

  private configuration: Configuration;

  constructor(private readonly loadingService: LoadingService,
              private readonly envConfigurationService: EnvConfigurationService,
              private readonly authorizationService: AuthorizationService) {

    this.configuration = this.envConfigurationService.getConfiguration();
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.configuration.name !== LOCALE) {
      console.log('DECRYPTED REQUEST: ', req);
    }
    const transformedRequest = this.transformRequest(req);
    this.loadingService.setLoading(true, this.getRequestId(transformedRequest));

    const step_1 = next.handle(transformedRequest).pipe(
        map((event) => {
          if (event instanceof HttpResponse) {
            // Usefull for debug!!!
            const evt = event.clone({ body: this.transformResponse(event) });
            return evt;
          }
          return event;
        }),
        catchError((event: HttpErrorResponse) => {
          this.loadingService.setLoading(false, this.getRequestId(transformedRequest));
          console.log('INTERCEPTOR ERROR: ', event); // TODO: Remove
          if (this.authorizationService.isAesEnabled(event.url)) {
            // Usefull for debug!!!
            const evt: HttpErrorResponse = {
              ...event,
              error: this.authorizationService.decryptResponseFromGateway(event.error),
            }
            throw evt;
          } else {
            throw event;
          }
        }),
        finalize(() => {
          setTimeout(() => {
            this.loadingService.setLoading(false, this.getRequestId(transformedRequest));
          });
        })
    );

    // Delay if mock mode
    let step_2 = step_1;
    if (transformedRequest.url.startsWith('http://localhost')) {
      step_2 = step_1.pipe(delay(500));
    }

    return step_2.pipe(
        map<HttpEvent<unknown>, HttpEvent<unknown>>((evt: HttpEvent<unknown>) => {
          if (evt instanceof HttpResponse) {
            this.loadingService.setLoading(false, transformedRequest.url);
          }
          return evt;
        })
    );
  }

  private getRequestId(request: HttpRequest<unknown>) {
    return `${request.method}_${request.url}`;
  }

  private transformRequest(request: HttpRequest<unknown>) {
    let bodyRequest = request.body;
    let payload = '';
    let digest = '';
    let signature = '';
    let url = request.url;
    let urlWithParams = request.urlWithParams;

    // Workaround for URL
    if (this.configuration.isa_login === 'enabled' || this.configuration.isaSSOSamlLogin === 'enabled') {
      url = url.replace('/api/rest', '');
      urlWithParams = urlWithParams.replace('/api/rest', '');
      urlWithParams = urlWithParams.replace(/'/g,'%27');
    }

    if (this.authorizationService.isSignatureEnabled(request)) {
      // Eventual symmetric encryption (AES)
      if (bodyRequest) {
        if (this.configuration.newApiGw !== 'enabled') {
          bodyRequest = this.authorizationService.encrypt(JSON.stringify(bodyRequest));
          payload = bodyRequest as string;
        }
      }

      // Digest computation
      digest = this.authorizationService.createDigest(payload);

      // Signature computation
      const postCookie = this.authorizationService.isPostCookie(request.url);
      signature = this.authorizationService.createSignature(
          digest,
          request.method,
          postCookie ? this.configuration.cookie_path : urlWithParams,
          postCookie ? this.configuration.gateway_host : ''
      );
    }

    let requestCloned = {};

    if (this.configuration.isa_login === 'enabled') {
      // Clone request
      requestCloned = {
        headers: request.headers.set('Signature', signature).set('Digest', digest),
        responseType: signature ? 'text' : request.responseType,
        body: bodyRequest,
        url,
      };
    } else if (this.configuration.isaSSOSamlLogin === 'enabled') {
      requestCloned = {
        body: bodyRequest,
        url,
      };
    }

    // Clone request
    return request.clone(requestCloned);

  }

  private transformResponse(event: HttpResponse<unknown>) {
    if (this.authorizationService.isAesEnabled(event.url)) {
      return this.authorizationService.decryptResponseFromGateway(event.body);
    }
    return event.body;
  }
}
