import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {UserService} from '@rgi/rx/auth';
import {Observable} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class RgiHeadersInterceptorService implements HttpInterceptor {
  constructor(private userService: UserService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.setRgiHeaders(req));
  }
  setRgiHeaders(req: HttpRequest<any>): HttpRequest<any> {
    if (this.userService.isLoggedIn()) {
      const user: any = this.userService.getUser();
      return req.clone({
        setHeaders: {
          RGI_idPv: user.salePoint.objectId,
          RGI_user: user.username
        }
      });
    }
    return req;
  }
}