/* upgrade parallelRouting */
export function pRouterFactory(i: any) {
  return i.get('parallelRouting');
}

export const pRouterProvider = {
  provide: 'parallelRouting',
  useFactory: pRouterFactory,
  deps: ['$injector']
};

/* upgrade coreAuthService */
export function coreAuthServiceFactory(i: any) {
  return i.get('coreAuthService');
}

export const coreAuthServiceProvider = {
  provide: 'coreAuthService',
  useFactory: coreAuthServiceFactory,
  deps: ['$injector']
};

/* upgrade authService */
export function authServiceFactory(i: any) {
  return i.get('authService');
}

export const authServiceProvider = {
  provide: 'authService',
  useFactory: authServiceFactory,
  deps: ['$injector']
};

/* upgrade angularGridInstance */
export function angularGridInstanceFactory(i: any) {
  return i.get('angularGridInstance');
}

export const angularGridInstanceProvider = {
  provide: 'angularGridInstance',
  useFactory: angularGridInstanceFactory,
  deps: ['$injector']
};

/* upgrade sessionService */
export function sessionServiceFactory(i: any) {
  return i.get('sessionService');
}

export const sessionServiceProvider = {
  provide: 'sessionService',
  useFactory: sessionServiceFactory,
  deps: ['$injector']
};

/* upgrade coreResult */
export function coreResultFactory(i: any) {
  return i.get('coreResult');
}

export const coreResultProvider = {
  provide: 'coreResult',
  useFactory: coreResultFactory,
  deps: ['$injector']
};


/* upgrade eventServiceServiceFactory */
export function eventServiceFactory(i: any) {
  return i.get('eventService');
}

export const eventServiceProvider = {
  provide: 'eventService',
  useFactory: eventServiceFactory,
  deps: ['$injector']
};

export function ssoServiceFactory(i: any) {
  return i.get('SSOService');
}

export const ssoServiceProvider = {
  provide: 'SSOService',
  useFactory: ssoServiceFactory,
  deps: ['$injector']
};
